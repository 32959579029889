import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      drawerMenuCategory: 'login'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      drawerMenuCategory: 'home'
    }
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Activity.vue'),
    meta: {
      drawerMenuCategory: 'activity'
    }
  },
  {
    path: '/validation',
    name: 'Validation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Validation.vue'),
    meta: {
      drawerMenuCategory: 'validation'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue'),
    meta: {
      drawerMenuCategory: 'help'
    }
  },  
  {
    path: '/project/sub-projects',
    name: 'Sub projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubProjectsList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },
  {
    path: '/project/plants',
    name: 'Plants',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectPlant.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },
  {
    path: '/project/insulations',
    name: 'Insulation',
    component: () => import(/* webpackChunkName: "about" */ '../views/InsulationList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },
  {
    path: '/project/tracings',
    name: 'Tracing',
    component: () => import(/* webpackChunkName: "about" */ '../views/TracingList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },  
  {
    path: '/project/mediums',
    name: 'Mediums',
    component: () => import(/* webpackChunkName: "about" */ '../views/MediumList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },
  {
    path: '/project/documents',
    name: 'Documents',
    component: () => import(/* webpackChunkName: "about" */ '../views/DocumentList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },  
  {
    path: '/project/instruments',
    name: 'Instruments',
    component: () => import(/* webpackChunkName: "about" */ '../views/InstrumentTypeCollectionList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },
  {
    path: '/project/equipment-types',
    name: 'Equipment types',
    component: () => import(/* webpackChunkName: "about" */ '../views/EquipmentTypeList.vue'),
    meta: {
      drawerMenuCategory: 'project'
    }
  },   
  {
    path: '/specs/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceList.vue'),
    meta: {
      drawerMenuCategory: 'specs'
    }
  },  
  {
    path: '/specs/pipespecs',
    name: 'Pipespecs',
    meta: {
      drawerMenuCategory: 'specs'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PipeSpecList.vue'),
  },
  {
    path: '/specs/valvespecs',
    name: 'Valvespecs',
    meta: {
      drawerMenuCategory: 'specs'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ValveSpecList.vue'),
  },
  {
    path: '/process/lines',
    name: 'Process - Lines',
    component: () => import(/* webpackChunkName: "about" */ '../views/LineList.vue'),
    meta: {
      drawerMenuCategory: 'process'
    }
  },
  {
    path: '/process/instruments',
    name: 'Process - Instruments',
    component: () => import(/* webpackChunkName: "about" */ '../views/InstrumentList.vue'),
    meta: {
      drawerMenuCategory: 'process'
    }
  },
  {
    path: '/process/valves',
    name: 'Process - Valves',
    component: () => import(/* webpackChunkName: "about" */ '../views/ValveList.vue'),
    meta: {
      drawerMenuCategory: 'process'
    }
  },
  {
    path: '/process/equipment/:category',
    name: 'Process - Equipment',
    component: () => import(/* webpackChunkName: "about" */ '../views/EquipmentList.vue'),
    meta: {
      drawerMenuCategory: 'process'
    }
  },
  {
    path: '/piping/lines',
    name: 'Piping - Lines',
    component: () => import(/* webpackChunkName: "about" */ '../views/LineList.vue'),
    meta: {
      drawerMenuCategory: 'piping'
    }
  },
  {
    path: '/instrumentation/instruments',
    name: 'Instrumentation - Instruments',
    component: () => import(/* webpackChunkName: "about" */ '../views/InstrumentList.vue'),
    meta: {
      drawerMenuCategory: 'instrumentation'
    }
  },
  {
    path: '/instrumentation/valves',
    name: 'Instrumentation - Valves',
    component: () => import(/* webpackChunkName: "about" */ '../views/ValveList.vue'),
    meta: {
      drawerMenuCategory: 'instrumentation'
    }
  },  
  // {
  //   path: '/electrical',
  //   name: 'Electrical',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/PipeSpecList.vue'),
  //   meta: {
  //     drawerMenuCategory: 'electrical'
  //   }
  // }
]

const router = new VueRouter({
  history: 'history',
  base: '',
  routes
})

// router.beforeEach((to, from, next) => {
//   Sentry.configureScope((scope) => scope.setTransactionName(to?.meta?.label));
//   next();
// });

export default router
